<template>
  <div class="buy_fiat">
    <div class="buy_box_top">{{ price }} {{ act_type }}/Hicoin</div>
    <div class="buy_box">
      <div class="b_title">Hicoin</div>
      <div class="b_inp">
        <el-input
          v-model="amount"
          :placeholder="$t('main.text87')"
          @input="amount_input"
          oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
        ></el-input>
      </div>

      <div class="b_title">{{ $t("main.text44") }}</div>
      <div class="b_inp2">
        <div class="b_inp2_1">
          <span slot="reference">{{ act_type }}</span>
        </div>
        <div class="b_inp2_2">{{ to_amount }}</div>
      </div>
      <div class="btm_txt">{{$t('main.text95')}} {{service_fee}} Hicoin</div>

      <div class="b_btn">
        <el-button
          class="btn_ok buy_btn"
          v-if="amount && to_amount"
          @click="buy_btn_fun"
        >
          {{ $t("main.text47") }}
        </el-button>
        <el-button class="btn_no buy_btn" v-else>
          {{ $t("main.text47") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import { orders_create } from "../../../api/api";

export default {
  computed: {
    price() {
      return this.$route.query.price;
    },
    agentUserid() {
      return this.$route.query.agentUserid;
    },
    user_rate() {
      return this.$route.query.user_rate;
    },
    user_fee() {
      return this.$route.query.user_fee;
    },
  },
  data() {
    return {
      amount: "",
      to_amount: "",
      act_type: "USDT",
      service_fee:0,//服务费
    };
  },
  mounted() {
    this.resetSetItem("hichat_title", this.$t("main.text48"));
  },
  methods: {
    buy_btn_fun() {
      orders_create({
        fromAmount: this.amount,
        price: this.price,
        toAmount: this.to_amount,
        currency: this.act_type,
        type: 1,
        agentUserid: this.agentUserid,
        payee_info_id: "",
        userFee:this.service_fee
      }).then((res) => {
        if (res.data.code == 200) {
          this.$router.push({
            path: "/buy_fiat_info",
            query: {
              order_id: res.data.data.order_id,
            },
          });
          this.msg("success", this.$t("main.text49"));
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    amount_input(){
      if(!this.amount) return;
      this.calc_amount();
    },
    // 计算
    calc_amount(){
      if(!this.amount) return;
      // this.price;//汇率
      this.to_amount='';//支付金额
      this.service_fee='';//服务费

      let fee=BigNumber(this.amount).times(this.user_rate).toFixed(2,0);
      // x.gt(y) // isGreaterThan 的简写，是否大于
      if(BigNumber(fee).gt(this.user_fee)){
        this.service_fee = fee*1;//服务费
      }else{
        this.service_fee = this.user_fee*1;//服务费
      }

      // 用户购买 toAmount = （输入数量 + 用户手续费）* 价格
      this.to_amount = BigNumber(this.amount).plus(this.service_fee).times(this.price).toFixed(2,0)*1;

    }
  },
};
</script>

<style scoped>
.buy_fiat {
  background: #f5f6f7;
  box-sizing: border-box;
  padding: 1.2rem;
}
.buy_box {
  border-radius: 0.375rem;
  background: #fff;
  box-sizing: border-box;
  padding: 1.25rem;
}
.b_title {
  color: #000;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.b_inp .el-input {
  height: 2.5rem;
}
.b_inp {
  margin-bottom: 1rem;
}
.b_inp2 {
  display: flex;
  height: 2.5rem;
  line-height: 2.5rem;
}
.b_inp2_1 {
  width: 5rem;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  background: #2982d8;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
}
.b_inp2_2 {
  flex: 1;
  background: #d6d6d6;
  text-indent: 1rem;
  border-radius: 0 0.25rem 0.25rem 0;
}
.act_type_list > div {
  line-height: 1.7rem;
}
.buy_btn {
  margin-top: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #fff;
}
.b_btn >>> .el-button {
  width: 100%;
}
.buy_box_top {
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.375rem 0.375rem 0rem 0rem;
  background: #2982d8;
  font-size: 0.875rem;
  font-weight: 600;
  box-sizing: border-box;
  padding: 0 1rem;
  color: #fff;
}
.btm_txt{
  color: #666;
  font-size: 14px;
  margin-top: 5px;
}
</style>
